import './bootstrap';
import '../css/app.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import Layout from "./Components/Layout.vue"; // Import your default layout
import { i18nVue } from 'laravel-vue-i18n';
import ToastPlugin from 'vue-toast-notification';

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        let page = pages[`./Pages/${name}.vue`]

        // Wrap the page with the default layout if not defined
        page.default.layout = page.default.layout || Layout;
        return page;
    },
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ToastPlugin, {
                position: 'top-right',
                duration: 5000,
                dismissible: true,
            })
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('./locales/*.json');
                    return await langs[`./locales/${lang}.json`]();
                }
            })
            .mount(el);
    },
});
